.wrapper {
  padding-top: 70px;

 
}

body h3 {
  margin-bottom: 15px;
}


/* nav bar */
#navbar {
  background-color: rgb(10, 4, 22) !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  font-size: 18px !important;
}


.navbar-brand a {
  color: #ffffff;

  position : fixed; 
   top : 0;

}

.form-wrapper,
.table-wrapper {
  max-width: 1500px;
  margin: 0 auto;
  border-width: 5px;
  border-color: #104b1e;
}

.table-wrapper {
  max-width: 1600px;
}

.edit-link {
  padding: 8px 12px;
  font-size: 1rem;
  line-height: normal;
  border-radius: 0.2rem;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  margin-right: 10px;
  position: relative;
  top: 12px;
}

.edit-link:hover {
  text-decoration: none;
  color: #ffffff;
}

#canvasContainer {
  padding: 5px;
  width:100%; height: 600px;
  border: 2px solid rgb(37, 143, 129);
  position:relative;
}


/* RL added */

.rl-canvas-element {
  text-align: center;
  margin: 2px;
  border-radius: 6px;
  background-color: #c9dde2;
  height:80px; width: 150px;
  border: 2px solid rgb(38, 32, 107);
  float: left;
  font-size: 0.8em;
}

.rl-canvas-box {
  text-align: center;
  margin: 2px;
 
 
  height:70px; width: 150px;
  border: 2px solid rgb(38, 32, 107);
  float: left;
  font-size: 0.8em;
}

.paraModel {
  margin-bottom: 5px;
}

.rl-edit-name{
  width:100%; height: 400px;
  font-size: 3rem;
  color: #425091;
}

.rl-just-blue{
 
  color: #425091;
}

.rl-attr-name{
  
  font-size: 1.2rem;
  color: #425091;
}

.rl-attr-proto{
  
  font-size: 1.1rem;
  color: #247438;
}


.rl_item {
  text-align: center;
  margin: 2px;
  float: left;
  font-size: 2em;
}

.rl_frameInput {

   background-color: #bfcae6;

}

.rl-auth-name{
  
  
  color: #247438;
}